.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  top: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-me-header {
  text-align: center;
}

.about-me-paragraph {
  font-size: larger;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding: 10px;
}


.header-space{
  background-color: skyblue;
  font-size: xx-large;
  text-align: center;
}

.link-nav {
  margin-left: 40px;
  margin-right: 40px;
  text-decoration: none;
  text-align: center;
  color: black;
}

.contact-info {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  height: 40px;
}

.currently-on-about {
  text-decoration: underline;
  font-weight: 700;
}

.link-card {
  width: 100px;
  display: flex;
}

.single-project {
  width: 875px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 60px;
}

.exp-card {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.aaron-title {
  font-size: 150px;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  margin-top: 0px;
}

.home-page{
  margin-bottom: 100px;
}

.about-me-page{
  margin-top: -50px;
}

.navbar{
  margin-left: auto;
  margin-right: auto;
}

.projects-page{
  margin-bottom: 150px;
}

.education{
  margin-bottom: 50px;
}

.completed-courses{
  margin-bottom: 150px;
}

.education-title{
  text-decoration: underline;
}

.completed-courses-title{
  text-decoration: underline;
}

.verify-badge{
  margin-left: 42px;
  margin-right: 42px;
}

.currently-on-section {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 60px;
}

.tech-certificate-section {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.skills{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: large;
}

.skills-title{
  text-decoration: underline;
  font-size: larger;
}